/**
 * Global state for handling user authentication, grabbing user advantage data,
 * and processing it into usable state/context objects for consumptions
 */

import React, { useReducer, useEffect, useCallback, useContext } from 'react'
import Router, { useRouter } from 'next/router'
import { SiteContext } from 'library/context/siteContext'
import { isEmpty, updateObject, countProperties, getQueryParamByKey } from 'library/utility/global'
import { userHasAccess, getUserSiteProducts, filterMembershipSubscriptions } from 'library/user/auth'
import { buildOrderDataState, buildAccountState, getPrimaryEmail } from 'library/user/user'
import { pushGtmEvent } from 'library/utility/gtm'
import {
  userLogout,
  getUserDataByLogin,
  getUserDataByToken,
  getUserDataByOtl,
  getUserDataByCn,
  validateMagicLink,
  validateVid2,
  getAllUserMeta,
  getUserMeta,
  saveUserMeta,
  removeUserMeta,
  updateUserMeta,
  getRecentMessages
} from 'library/api/user'

// Generate context
export const UserContext = React.createContext()

// Reducer initial state
const initialState = {
  isAuthenticated: false,
  isAuthenticating: false,
  isLoading: true,
  error: '',
  aggregateData: null,
  subscriptionView: [],
  listView: [],
  productView: [],
  accessView: [],
  accessibleFulfillment: {},
  account: {},
  userMeta: {}
}

// State store reducer 
const reducer = (state, action) => {
  switch (action.type) {
    case 'SEND':
      return updateObject(state, { isAuthenticating: true, error: '' })
    case 'SUCCESS':
      return updateObject(initialState, {
        isAuthenticated: true,
        isLoading: false,
        aggregateData: action.aggregateData,
        subscriptionView: action.subscriptionView,
        productView: action.productView,
        accessView: action.accessView,
        listView: action.listView,
        account: action.account,
        accessibleFulfillment: state.accessibleFulfillment
      })
    case 'ERROR':
      clearLocalUser()
      return updateObject(initialState, { isLoading: false, error: action.error })
    case 'RESET':
      clearLocalUser()
      return updateObject(initialState, { isLoading: false })
    case 'SET_ACCESSIBLE_FULFILLMENT':
      return updateObject(state, {
        accessibleFulfillment: action.accessibleFulfillment,
        subscriptionView: action.subscriptionView
      })
    case 'SET_MEMBERSHIP':
      return updateObject(state, {
        membership: action.membership
      })
    case 'GET_USER_META':
      // Remove unrelated PP data from pushing to state
      if (typeof action.userMeta === 'object' && action.userMeta !== null) {
        for (const [key, value] of Object.entries(action.userMeta)) {
          if (key.includes('pp_')) {
            continue
          } else {
            delete action.userMeta[key]
          }
        }
      } else {
        action.userMeta = {}
      }
      return updateObject(state, {
        userMeta: action.userMeta,
      })
  }
}

// Remove users local storage and cookies
const clearLocalUser = () => {
  localStorage.removeItem('tid')
  userLogout()
}

export const UserContextProvider = ({ children }) => {

  ////////////////////////////////////////////////////////////////////////////
  // Set hooks
  ////////////////////////////////////////////////////////////////////////////

  // Set router object
  const router = useRouter()

  // Set user state as reducer
  const [userState, userDispatch] = useReducer(reducer, initialState)

  // Get fulfillment products from site config
  const siteContext = useContext(SiteContext)



  ////////////////////////////////////////////////////////////////////////////
  // Handle login/auth functionality
  ////////////////////////////////////////////////////////////////////////////

  /**
   * On state-less load, attempt auto login by local user token or derive from auto login
   */
  useEffect(() => {
    const autoLoginToken = getQueryParamByKey('user_token', router.asPath),
          autoLoginOtl = getQueryParamByKey('otl', router.asPath) && getQueryParamByKey('otl', router.asPath),
          autoLoginCn = getQueryParamByKey('cn', router.asPath) && getQueryParamByKey('cn', router.asPath),
          autoLoginMagicLink = getQueryParamByKey('sk', router.asPath) && getQueryParamByKey('sk', router.asPath),
          autoLoginVid2 = getQueryParamByKey('vid2', router.asPath) && getQueryParamByKey('vid2', router.asPath),
          user_token = localStorage.getItem('tid') ? localStorage.getItem('tid') : autoLoginToken

    switch (true) {

      // Token login first
      case user_token && !userState.isAuthenticated:
        handleTokenLogin(user_token)
        userDispatch({ type: 'SEND' })
        break

      // Auto login via otl param
      case autoLoginOtl && !userState.isAuthenticated:
        handleAutoLoginByOtl(autoLoginOtl)
        userDispatch({ type: 'SEND' })
        break

      // Auto login via cn param
      case autoLoginCn && !userState.isAuthenticated:
        handleAutoLoginByCn(autoLoginCn)
        userDispatch({ type: 'SEND' })
        break

      // Auto login with magic link JWT
      case autoLoginMagicLink && !userState.isAuthenticated:
        handleAutoLoginByMagicLink(autoLoginMagicLink)
        userDispatch({ type: 'SEND' })
        break

      // Auto login with vid2 link from Blueshift
      case autoLoginVid2 && !userState.isAuthenticated:
        handleAutoLoginByVid2(autoLoginVid2)
        userDispatch({ type: 'SEND' })
        break

      default:
        userDispatch({ type: 'RESET' })
        break
    }

  }, [])

  /**
   * Login by form submission
   * @param  {obj} @event
   * @return {null}
   */
  const handleFormLogin = useCallback(event => {
    event.preventDefault()
    const formData = new FormData(event.target),
          username = formData.get('username'),
          password = formData.get('password'),
          data = getUserDataByLogin(username, password)
    resolveUserContext(data, username, 'form')
  }, [])

  /**
   * Login by user token
   * @param  {str|int} user_token
   * @param  {str} username
   * @return {null}
   */
  const handleTokenLogin = useCallback((user_token, username) => {
    const data = getUserDataByToken(user_token)
    resolveUserContext(data)
  }, [])

  /**
   * Auto login by one time login
   * @param  {str} otl
   * @return {null}
   */
  const handleAutoLoginByOtl = useCallback((otl) => {
    const data = getUserDataByOtl(otl)
    resolveUserContext(data, '', 'otl')
  }, [])

  /**
   * Auto login by customer number
   * @param  {str} cn
   * @return {null}
   */
  const handleAutoLoginByCn = useCallback((cn) => {
    const data = getUserDataByCn(cn)
    resolveUserContext(data, '', 'auto')
  }, [])

  /**
   * Auto login by magic link JWT
   * @param  {str} jwt
   * @return {null}
   */
  const handleAutoLoginByMagicLink = useCallback((jwt) => {
    const data = validateMagicLink(jwt)
    data.then(response => {
      if (response.data.csid) {
        let cn = new Buffer(response.data.csid).toString('base64')
        resolveUserContext(getUserDataByCn(cn), '', 'magiclink')
      } else {
        resolveUserContext(data, '', 'magiclink')
      }
    })
  }, [])

  /**
   * Auto login by vid2 query
   * @param  {str} vid2
   * @return {null}
   */
  const handleAutoLoginByVid2 = useCallback((vid2) => {
    const data = validateVid2(vid2)
    data.then(response => {
      if (response.data.customerNumber) {
        let cn = new Buffer(response.data.customerNumber).toString('base64')
        resolveUserContext(getUserDataByCn(cn), '', 'vid2')
      } else {
        resolveUserContext(data, '', 'vid2')
      }
    })
  }, [])

  /**
   * Force logout, clear context and remove local storage items
   * @return {null}
   */
  const handleLogout = useCallback(() => {
    pushGtmEvent({ event: 'UserLogout' })
    userDispatch({ type: 'RESET' })
  }, [])

  /**
   * Check if user has access
   * @param  {str|arr|obj} product
   * @param  {arr} type
   * @param  {obj} params Currently only supports { subType: 'value', memberCat: 'value' }
   * @return {bool}
   */
  const handleUserAccess = useCallback((product, type, params) => {
    return userHasAccess(userState, product, type, params)
  }, [userState])

  /**
   * Resolve the user data promise and build the context state for consumption
   * @param  {obj} promise
   * @param  {str} username
   * @param  {str} method
   * @return {null}
   */
  const resolveUserContext = useCallback((promise, username, method) => {

    // Set loading until promise resolution
    if (!userState.isAuthenticating) {
      userDispatch({ type: 'SEND' })
    }

    // Currently only returning 200 OK response, so error handling dependent on response content
    promise.then(response => {

      switch (true) {

        // Constructed error
        case response.data.hasOwnProperty('error'):
          pushGtmEvent({ 
            event: 'UserLoginFailed',
            reason: response.data.type
          })
          userDispatch({ type: 'ERROR', error: response.data.error })
          break

        // Successful data return
        case !isEmpty(response.data.authenticationView):

          // After advantage upgrade,
          const orderDataObject = {
            subscriptionView: response.data.subscriptionView,
            listView: response.data.listView,
            productView: response.data.productView,
            accessView: response.data.accessView,
            tempOrderView: response.data.tempOrderView
          }
          const orderData = buildOrderDataState(orderDataObject)
          const account = buildAccountState(response.data, username)

          // Refresh local storage
          localStorage.setItem('tid', response.data.authenticationView[0].user_token)

          // Send login event if defined
          if (method) {
            pushGtmEvent({ 
              event: 'UserLogin',
              method: method,
              customerNumber: account.customerNumber
            })
          }

          userDispatch({
            type: 'SUCCESS',
            aggregateData: response.data,
            account: account,
            ...orderData
          })
          break

        // Default
        default:
          userDispatch({ type: 'RESET' })
      }
    }).catch(error => {

      // Unknown error
      pushGtmEvent({ 
        event: 'UserLoginFailed',
        reason: 'Unknown'
      })

      userDispatch({ type: 'ERROR', error: 'There was a problem verifying your account. Please try again momentarily.' })
    })
  }, [])



  ////////////////////////////////////////////////////////////////////////////
  // Build user context/state data
  ////////////////////////////////////////////////////////////////////////////

  /**
   * On load/change get user owned products
   */
  useEffect(() => {
    // Only pass user state if its our updated state
    if (siteContext.fulfillmentProduct && userState.aggregateData) {
      handleFilterUserProducts(userState)
    }
  }, [siteContext.fulfillmentProduct, userState.aggregateData])

  /**
   * Generate list of contetful products that the user has access to and add to userState
   * @param  {obj} updatedUserState
   * @return {null}
   */
  const handleFilterUserProducts = useCallback((updatedUserState) => {

    // Get products
    const products = getUserSiteProducts(siteContext.fulfillmentProduct, updatedUserState)

    // Filter the user subscriptions and update to include membership access features
    const filteredSubscriptions = filterMembershipSubscriptions(siteContext.fulfillmentProduct, updatedUserState)

    // If user has no products supported by the site, kick back to login
    if (isEmpty(products) || !products.subscription) {

      // User doesn't own any fulfillment products
      pushGtmEvent({ 
        event: 'UserLoginFailed',
        reason: 'No products',
        customerNumber: userState.account.customerNumber
      })

      userDispatch({
        type: 'ERROR',
        error: 'Your account does not have the appropriate access.'
      })

    } else {
      userDispatch({
        type: 'SET_ACCESSIBLE_FULFILLMENT',
        accessibleFulfillment: products,
        subscriptionView: filteredSubscriptions
      })
    }

  }, [siteContext.fulfillmentProduct])

  /**
   * Once authenticated, get all user meta data and append to user state/context
   */
  useEffect(() => {
    if (userState.isAuthenticated) {
      handleGetUserMeta()
      handleLastLogin()
      handleSetMembership()
    }
  }, [userState.isAuthenticated])

  // Update recent messages after we have the information to do so
  useEffect(() => {
    if (userState.isAuthenticated) {
      handleRecentMessages()
    }
  }, [userState.accessibleFulfillment, userState.userMeta])

  /**
   * Get all user meta
   */
  const handleGetUserMeta = useCallback(() => {
    const promise = getAllUserMeta(userState.account.customerNumber)
    promise.then(response => {
      userDispatch({
        type: 'GET_USER_META',
        userMeta: response.data
      })
    })
  }, [userState])

  /**
   * Generate last login user meta
   * @return {null}
   */
  const handleLastLogin = useCallback(() => {

    // Set last login key
    const metaKey = 'pp_last_login'

    // Set new date
    const current = new Date()

    // Update last login
    updateUserMeta(userState.account.customerNumber, metaKey, current.toISOString())

  }, [userState])

  /**
   * Cleanup usermeta of read messages from recent articles
   * @return {null}
   */
  const handleRecentMessages = useCallback(() => {

    // Set meta keys
    const metaKeyRead = 'pp_read_messages',
          metaKeyUnread = 'pp_unread_messages'

    // Skip if we dont' have the user meta yet
    if (isEmpty(userState.userMeta)) return

    // Get read/unread messages from user meta
    const readMessages = userState.userMeta[metaKeyRead] ? userState.userMeta[metaKeyRead] : []
    const unreadMessages = userState.userMeta[metaKeyUnread] ? userState.userMeta[metaKeyUnread] : []

    // Get recent messages from last 32 hours
    const promise = getRecentMessages(userState.accessibleFulfillment)

    // Resolve recent messages
    promise.then(response => {

      // Return empty promise
      if (response === undefined) return

      // Get array of recent article ids
      let newMessageIds = []
      if (response.data && Array.isArray(response.data)) {
        newMessageIds = response.data.map(entry => entry.cfId)
      }

      // If no messages are returned, drop out
      if (isEmpty(newMessageIds)) return

      // To allow for rare multi values, pare down the list to unique user meta
      const filteredReadMessages = readMessages.filter((c, index) => {
          return readMessages.indexOf(c) === index;
      })
      const filteredUnreadMessages = unreadMessages.filter((c, index) => {
          return unreadMessages.indexOf(c) === index;
      })

      // Start by cleaning up read messages
      for (let i = 0, len = filteredReadMessages.length; i < len; i++) {
        if (!newMessageIds.includes(filteredReadMessages[i])) {
          removeUserMeta(userState.account.customerNumber, metaKeyRead, filteredReadMessages[i])
        }
      }

      // Clean up unread
      for (let i = 0, len = filteredUnreadMessages.length; i < len; i++) {
        if (!newMessageIds.includes(filteredUnreadMessages[i])) {
          removeUserMeta(userState.account.customerNumber, metaKeyUnread, filteredUnreadMessages[i])
        }
      }

      // Add each unread message to user meta if it doesn't already exist
      for (let i = 0, len = newMessageIds.length; i < len; i++) {
        if (!filteredUnreadMessages.includes(newMessageIds[i]) && !filteredReadMessages.includes(newMessageIds[i])) {
          saveUserMeta(userState.account.customerNumber, metaKeyUnread, newMessageIds[i])
        }
      }
    })

  }, [userState])

  /**
   * Set highest membership for user
   * @return {null}
   */
  const handleSetMembership = () => {
    // Today, we only care about OWC
    // TODO: Make dynamic with TBD membership table
    const hasOwc = userHasAccess(userState, 'OWC', 'subscriptionView', { memberCat: 'OW' })
    if (hasOwc) userDispatch({ type: 'SET_MEMBERSHIP', membership: 'OWC' })
  }

  return (
    <UserContext.Provider value={{
      formLogin: handleFormLogin,
      tokenLogin: handleTokenLogin,
      logout: handleLogout,
      userState: userState,
      userDispatch: userDispatch,
      userHasAccess: handleUserAccess,
      refreshUserMeta: handleGetUserMeta,
      refreshReadMessages: handleRecentMessages
    }}>
      {children}
    </UserContext.Provider>
  )
}